import Empty from "../../images/components/empty.svg"
import Cards from "../../images/components/cards.svg"
import Tabs from "../../images/components/tabs.svg"
import Pagination from "../../images/components/pagination.svg"
import Select from "../../images/components/selects.svg"
import Textarea from "../../images/components/textarea.svg"
import Button from "../../images/components/buttons.svg"
import Accordion from "../../images/components/accordions.svg"
import Avatars from "../../images/components/avatars.svg"
import Alerts from "../../images/components/alerts.svg"
import Badges from "../../images/components/badges.svg"
import Breadcrumbs from "../../images/components/breadcrumbs.svg"
import Carousel from "../../images/components/carousel.svg"
import Checkboxes from "../../images/components/checkboxes.svg"
import Dropdowns from "../../images/components/dropdowns.svg"
import Inputs from "../../images/components/inputs.svg"
import Layouts from "../../images/components/layouts.svg"
import Ratings from "../../images/components/ratings.svg"
import Modals from "../../images/components/modals.svg"
import Progress from "../../images/components/progress.svg"
import Radios from "../../images/components/radios.svg"
import Tables from "../../images/components/tables.svg"
import Tooltips from "../../images/components/tooltips.svg"
import Toggles from "../../images/components/toggles.svg"
import Footer from "../../images/components/footer.svg"
import Spinners from "../../images/components/spinners.svg"
import Notifications from "../../images/components/notifications.svg"
import NavigationBars from "../../images/components/navbar.svg"
import Testimonials from "../../images/components/testimonials.svg"
import Slider from "../../images/components/slider.svg"
import PricingTables from "../../images/components/pricing-tables.svg"
import Feeds from "../../images/components/feeds.svg"
import Lists from "../../images/components/lists.svg"
import SideNav from "../../images/components/sidenav.svg"
import Feature from "../../images/components/feature.svg"
import Stacks from "../../images/components/stacks.svg"
import ButtonGroups from "../../images/components/button-groups.svg"
import ButtonSwitchers from "../../images/components/button-switchers.svg"
import ChipNotifications from "../../images/components/chip-notifications.svg"
import FABs from "../../images/components/fab.svg"
import Datepicker from "../../images/components/datepicker.svg"
import FileInput from "../../images/components/file-input.svg"
import SearchInput from "../../images/components/search-input.svg"
import PhoneInput from "../../images/components/phone-input.svg"
import NumberInput from "../../images/components/number-input.svg"
import AppButton from "../../images/components/app-button.svg"

export const navigationItems = [
  {
    navigationCategory: "General",
    navigationCategoryLink: "#",
    navigationSubCategories: [
      {
        subCategory: "Accordions",
        subCategoryLink: "/components/accordions/",
        subCategoryImage: Accordion,
        subCategoryVarNumber: 6,
      },

      {
        subCategory: "Avatars",
        subCategoryLink: "/components/avatars/",
        subCategoryImage: Avatars,
        subCategoryVarNumber: 171,
        featured: true,
      },
      {
        subCategory: "Badges",
        subCategoryLink: "/components/badges/",
        subCategoryImage: Badges,
        subCategoryVarNumber: 12,
      },
      {
        subCategory: "Cards",
        subCategoryLink: "/components/cards/",
        subCategoryImage: Cards,
        subCategoryVarNumber: 13,
        featured: true,
      },
      {
        subCategory: "Carousel",
        subCategoryLink: "/components/carousels/",
        subCategoryImage: Carousel,
        subCategoryVarNumber: 8,
      },

      {
        subCategory: "Features",
        subCategoryLink: "/components/features/",
        subCategoryImage: Feature,
        subCategoryVarNumber: 12,
      },
      {
        subCategory: "Feeds",
        subCategoryLink: "/components/feeds/",
        subCategoryImage: Feeds,
        subCategoryVarNumber: 16,
        updated: true,
      },
      {
        subCategory: "Layouts",
        subCategoryLink: "/components/layouts/",
        subCategoryImage: Layouts,
        subCategoryVarNumber: 14,
      },
      {
        subCategory: "Lists",
        subCategoryLink: "/components/lists/",
        subCategoryImage: Lists,
        subCategoryVarNumber: 54,
      },
      {
        subCategory: "Pricing Tables",
        subCategoryLink: "/components/pricing/",
        subCategoryImage: PricingTables,
        subCategoryVarNumber: 9,
      },
      {
        subCategory: "Ratings",
        subCategoryLink: "/components/ratings/",
        subCategoryImage: Ratings,
        subCategoryVarNumber: 16,
      },
      {
        subCategory: "Sliders",
        subCategoryLink: "/components/sliders",
        subCategoryImage: Slider,
        subCategoryVarNumber: 7,
      },
      {
        subCategory: "Stacks",
        subCategoryLink: "#",
        subCategoryImage: Stacks,
        comingSoon: true,
      },
      {
        subCategory: "Tables",
        subCategoryLink: "/components/tables/",
        subCategoryImage: Tables,
        subCategoryVarNumber: 8,
      },
      {
        subCategory: "Tabs",
        subCategoryLink: "/components/tabs/",
        subCategoryImage: Tabs,
        subCategoryVarNumber: 15,
        featured: true,
      },
      {
        subCategory: "Testimonials",
        subCategoryLink: "/components/testimonials/",
        subCategoryImage: Testimonials,
        subCategoryVarNumber: 22,
      },
    ],
  },
  {
    navigationCategory: "Buttons",
    navigationCategoryLink: "#",
    navigationSubCategories: [
      {
        subCategory: "App Buttons",
        subCategoryLink: "/components/app-buttons/",
        subCategoryImage: AppButton,
        subCategoryVarNumber: 12,
      },
      {
        subCategory: "Buttons",
        subCategoryLink: "/components/buttons/",
        subCategoryImage: Button,
        subCategoryVarNumber: 129,
        featured: true,
      },
      {
        subCategory: "Button Groups",
        subCategoryLink: "/components/button-groups/",
        subCategoryImage: ButtonGroups,
        subCategoryVarNumber: 78,
      },
      {
        subCategory: "Button Switchers",
        subCategoryLink: "/components/button-switchers/",
        subCategoryImage: ButtonSwitchers,
        subCategoryVarNumber: 18,
      },
      {
        subCategory: "Dropdowns",
        subCategoryLink: "/components/dropdowns/",
        subCategoryImage: Dropdowns,
        subCategoryVarNumber: 2,
        featured: true,
      },
      {
        subCategory: "FABs",
        subCategoryLink: "/components/fabs/",
        subCategoryImage: FABs,
        subCategoryVarNumber: 20,
      },
    ],
  },
  {
    navigationCategory: "Navigation",
    navigationCategoryLink: "#",
    navigationSubCategories: [
      {
        subCategory: "Breadcrumbs",
        subCategoryLink: "/components/breadcrumbs/",
        subCategoryImage: Breadcrumbs,
        subCategoryVarNumber: 6,
      },
      {
        subCategory: "Footers",
        subCategoryLink: "/components/footers/",
        subCategoryImage: Footer,
        subCategoryVarNumber: 9,
        featured: true,
        updated: true,
      },
      {
        subCategory: "Pagination",
        subCategoryLink: "/components/pagination/",
        subCategoryImage: Pagination,
        subCategoryVarNumber: 20,
      },
      {
        subCategory: "Side Navigation",
        subCategoryLink: "/components/side-navigation/",
        subCategoryImage: SideNav,
        subCategoryVarNumber: 5,
      },
      {
        subCategory: "Navbars",
        subCategoryLink: "/components/navbars/",
        subCategoryImage: NavigationBars,
        subCategoryVarNumber: 5,
        featured: true,
      },
    ],
  },
  {
    navigationCategory: "Feedback",
    navigationCategoryLink: "#",
    navigationSubCategories: [
      {
        subCategory: "Alerts",
        subCategoryLink: "/components/alerts/",
        subCategoryImage: Alerts,
        subCategoryVarNumber: 35,
      },
      {
        subCategory: "Chip Notifications",
        subCategoryLink: "/components/chip-notifications",
        subCategoryImage: ChipNotifications,
        subCategoryVarNumber: 30,
        updated: true,
      },
      {
        subCategory: "Modals",
        subCategoryLink: "/components/modals/",
        subCategoryImage: Modals,
        subCategoryVarNumber: 5,
        featured: true,
      },
      {
        subCategory: "Notifications",
        subCategoryLink: "/components/notifications/",
        subCategoryImage: Notifications,
        subCategoryVarNumber: 24,
      },
      {
        subCategory: "Progress bars",
        subCategoryLink: "/components/progress-bars/",
        subCategoryImage: Progress,
        subCategoryVarNumber: 28,
      },
      {
        subCategory: "Spinners",
        subCategoryLink: "/components/spinners/",
        subCategoryImage: Spinners,
        subCategoryVarNumber: 25,
      },
      {
        subCategory: "Tooltips",
        subCategoryLink: "/components/tooltips/",
        subCategoryImage: Tooltips,
        subCategoryVarNumber: 8,
      },
    ],
  },
  {
    navigationCategory: "Form Elements",
    navigationCategoryLink: "#",
    navigationSubCategories: [
      {
        subCategory: "Checkboxes",
        subCategoryLink: "/components/form-elements/checkboxes/",
        subCategoryImage: Checkboxes,
        subCategoryVarNumber: 6,
      },
      {
        subCategory: "Datepickers",
        subCategoryLink: "/components/form-elements/datepickers/",
        subCategoryImage: Datepicker,
        subCategoryVarNumber: 12,
      },
      {
        subCategory: "File Inputs",
        subCategoryLink: "/components/form-elements/file-inputs",
        subCategoryImage: FileInput,
        subCategoryVarNumber: 30,
      },
      {
        subCategory: "Input Groups",
        subCategoryLink: "/components/form-elements/input-groups/",
        subCategoryImage: Inputs,
        subCategoryVarNumber: 28,
      },
      {
        subCategory: "Number Inputs",
        subCategoryLink: "#",
        subCategoryImage: NumberInput,
        comingSoon: true,
      },
      {
        subCategory: "Phone Inputs",
        subCategoryLink: "#",
        subCategoryImage: PhoneInput,
        comingSoon: true,
      },
      {
        subCategory: "Radio Groups",
        subCategoryLink: "/components/form-elements/radio-groups/",
        subCategoryImage: Radios,
        subCategoryVarNumber: 2,
      },
      {
        subCategory: "Search Inputs",
        subCategoryLink: "/components/form-elements/search-inputs/",
        subCategoryImage: SearchInput,
        subCategoryVarNumber: 4,
      },
      {
        subCategory: "Select Menus",
        subCategoryLink: "/components/form-elements/select-menus/",
        subCategoryImage: Select,
        subCategoryVarNumber: 12,
      },
      {
        subCategory: "Textareas",
        subCategoryLink: "/components/form-elements/textareas/",
        subCategoryImage: Textarea,
        subCategoryVarNumber: 16,
      },
      {
        subCategory: "Toggles",
        subCategoryLink: "/components/form-elements/toggles/",
        subCategoryImage: Toggles,
        subCategoryVarNumber: 18,
      },
    ],
  },
]
